/* eslint-disable max-len */
import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled, { css } from 'styled-components'

import BannerImage from '../../assets/static/banner/banner_hsinchu.jpg'
import EnvironmentImg1 from '../../assets/static/clinic/hsinchu_environment1.png'
import EnvironmentImg2 from '../../assets/static/clinic/hsinchu_environment2.png'
import EnvironmentImg3 from '../../assets/static/clinic/hsinchu_environment3.png'
import consultImage from '../../assets/static/consult/consult1.jpg'
import Button from '../../components/Button'
import { getClinicInfo } from '../../components/Clinic/getClinicInfo'
import { GridItem, GridLayout } from '../../components/Grid'
import ClinicLayout from '../../components/Layout/ClinicLayout'
import Seo from '../../components/Seo'
import { space } from '../../constants/length'
import { Hsinchu, HsinchuSecondary, Taichung } from '../../constants/newColor'
import { Body, H2 } from '../../constants/newText'
import { viewport } from '../../constants/viewport'
import { Location } from '../../types/types'
import CaseSection from './components/CaseSection'
import Consult from './components/Consult'
import DoctorSection from './components/Doctor'
import GoogleMap from './components/GoogleMap'
import LogoInfo from './components/LogoInfo'
import OtherClinic from './components/OtherClinic'
import TimetableSection from './components/Timetable'
import { hsinchuTimetableData } from './components/Timetable/staticData'

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TitleDecStyle = css`
  width: 8px;
  height: 1.85rem;
  border-radius: 24px;
  margin: 0 ${space.m}px;
`

const ContentTitle = styled.h2`
  ${H2}
  display: flex;
  align-items: center;
  margin: 0 auto ${space.l}px auto;

  &:before {
    ${TitleDecStyle}
    content: '';
    display: block;
    background-color: ${HsinchuSecondary};
  }
  &:after {
    ${TitleDecStyle}
    content: '';
    display: block;
    background-color: ${Hsinchu};
  }
`

const Img = styled.img`
  width: 100%;
  object-fit: content;
`

const Info = styled.div`
  ${Body}
  display: flex;
  flex-direction: column;
  align-items: center;
`
const MoreCase = styled.a`
  display: inline-block;
  margin-top: ${space.xl}px;
`

const ConsultWrapper = styled.div`
  width: 100%;
  position: relative;
  @media (min-width: ${viewport.desktop}px) {
    height: 406px;
  }
  @media (max-width: ${viewport.desktop - 1}px) and (min-width: ${viewport.tablet}px) {
    height: 294px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    height: 200px;
  }
`

const ClinicHsinchu = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()
  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'breadcrumb.clinic' }),
      path: '/clinic'
    },
    {
      label: formatMessage({ id: 'clinic.hsinchu' }),
      path: '/clinic/hsinchu'
    }
  ]

  return (
    <ClinicLayout imageUrl={BannerImage} pageBannerInfo={formatMessage({ id: 'banner.hsinchu' })} routes={routes} {...props}>
      <Seo
        title='【竹北牙醫推薦】橙蒔美學牙醫診所：隱形矯正、矯正專科、陶瓷貼片'
        metaDescription='新竹竹北牙醫第一推薦-橙蒔美學牙醫診所，鄰近遠東百貨 竹北店、新竹縣體育館旁，提供隱形牙套、戴蒙矯正、陶瓷貼片、人工植牙、兒童MRC等多種牙科治療，『先橙蒔，再成交』是橙蒔服務的堅持。'
      />
      <LogoInfo location={Location.Hsinchu} />
      <Content>
        <GridLayout templateColumns={['100%']} rowGap={['24px', '120px']}>
          <GridItem>
            <ContentTitle>關於我們</ContentTitle>
            <Info>
              <p>『先橙蒔，再成交』</p>
              <p />
              <p>
                位於新竹縣竹北大遠百、竹北交流道下、新竹縣體育館的「橙蒔美學牙醫」，來自台北東區SUNLIGHT美學牙醫集團，一起提供給您最優質的
                牙齒矯正、陶瓷貼片、人工植牙、全口重建等服務。
                融合日式服務款待精神與台灣專業牙科治療，『對待患者如同家人朋友』是我們的服務宗旨，讓日不落團隊一起帶給您最溫暖的看診時光。
              </p>
            </Info>
          </GridItem>
          <GridItem>
            <GridLayout templateColumns={['repeat(3, 1fr)']} columnGap={['16px', '60px']}>
              <Img src={EnvironmentImg1} alt='新竹橙蒔美學牙醫診所環境照1' />
              <Img src={EnvironmentImg2} alt='新竹橙蒔美學牙醫診所環境照2' />
              <Img src={EnvironmentImg3} alt='新竹橙蒔美學牙醫診所環境照3' />
            </GridLayout>
          </GridItem>
        </GridLayout>
      </Content>
      <Content>
        <ContentTitle>專業醫師團隊</ContentTitle>
        <DoctorSection page={Location.Hsinchu} />
      </Content>
      <Content>
        <ContentTitle>矯正心得分享</ContentTitle>
        <CaseSection location={[Location.Hsinchu]} />
        <MoreCase href={`/case?location=${Location.Hsinchu}`}>
          <Button label='MORE &#9658;' type='secondary' />
        </MoreCase>
      </Content>
      <Content>
        <ConsultWrapper>
          <Consult color={Hsinchu} imageUrl={consultImage} />
        </ConsultWrapper>
      </Content>
      <Content>
        <ContentTitle>門診資訊</ContentTitle>
        <TimetableSection
          data={hsinchuTimetableData}
          primaryColor={Hsinchu}
          secondaryColor={HsinchuSecondary}
          oddColor={Taichung}
          tel={getClinicInfo({ location: Location.Hsinchu, value: 'tel' })}
          line={getClinicInfo({ location: Location.Hsinchu, value: 'socialMedia_line' })}
        />
      </Content>
      <Content id='location'>
        <GoogleMap lat={24.81901} lng={121.02411} title='sunlight_hsinchu' add={getClinicInfo({ location: Location.Hsinchu, value: 'address' })} />
      </Content>
      <Content>
        <ContentTitle>其他診所</ContentTitle>
        <OtherClinic />
      </Content>
    </ClinicLayout>
  )
}

export default ClinicHsinchu
